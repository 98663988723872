import { default as indexiElVe6VRvqMeta } from "/usr/src/nuxt-app/pages/contact/index.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
export default [
  {
    name: indexiElVe6VRvqMeta?.name ?? "contact___en-US",
    path: indexiElVe6VRvqMeta?.path ?? "/contact",
    meta: indexiElVe6VRvqMeta || {},
    alias: indexiElVe6VRvqMeta?.alias || [],
    redirect: indexiElVe6VRvqMeta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: indexAoBMx6PnC1Meta?.name ?? "index___en-US",
    path: indexAoBMx6PnC1Meta?.path ?? "/",
    meta: indexAoBMx6PnC1Meta || {},
    alias: indexAoBMx6PnC1Meta?.alias || [],
    redirect: indexAoBMx6PnC1Meta?.redirect || undefined,
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  }
]