export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"application-name","content":"MCS"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"keywords","content":"MCS, tools, modules, ia, torrent, cloud, storage"},{"name":"robots","content":"index, follow"},{"name":"language","content":"fr"},{"http-equiv":"Cache-Control","content":"no-cache, no-store, must-revalidate"},{"http-equiv":"Expires","content":"0"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"}],"link":[],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"fr"},"title":"MCS","charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null